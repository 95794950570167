import $ from 'jquery';
import Pagination from './Pagination';

export default class CreatePagination {
  constructor(paramObj) {
    this.defaults = {
      perPage: 3,
      activePage: 2,
      container: '.pagination-cnt'
    };

    if (paramObj === undefined) {
      paramObj = this.defaults;
    }

    const { perPage, activePage, container } = paramObj;
    const d = this.defaults;

    this.perPage = perPage === undefined ? d.perPage : perPage;
    this.activePage = activePage === undefined ? d.activePage : activePage;
    this.container = container === undefined ? d.container : container;

    this.createPaginationInstances();
  }

  createPaginationInstances() {
    $(this.container).each((index, el) => {
      this[`pagination${index}`] = new Pagination({
        container: this.container,
        perPage: this.perPage,
        activePage: this.activePage,
        el
      });
    });
  }
}
