import $ from 'jquery';

export default class Pagination {
  constructor(paramObj) {
    this.perPage = paramObj.perPage;
    this.activePage = paramObj.activePage;
    this.container = paramObj.container;
    this.el = paramObj.el;
    this.currentItems = [];
    this.totalItems = [];

    this.eventHandlers();
    this.handlePagination();
  }

  loadRows(event) {
    const $this = event === undefined
      ? $(this.el)
      : $(event.currentTarget).closest(this.container);

    const pgnCntTag = $this.prop('tagName').toLowerCase();

    switch (pgnCntTag) {
      case 'table':
        this.totalItems = $this.find('tbody tr');
        break;

      default:
        this.totalItems = $this.find('.pagination-row');
    }
  }

  editClassNames() {
    this.totalItems.each((index, item) => {
      $(item).removeClass('show');
    });

    this.currentItems.each((index, item) => {
      $(item).addClass('show');
    });
  }

  placeRows(event) {
    const $this = event === undefined
      ? $(this.el)
      : $(event.currentTarget).closest(this.container);

    const pgnCntTag = $this.prop('tagName').toLowerCase();

    this.editClassNames();

    switch (pgnCntTag) {
      case 'table':
        this.placeTableRows($this);
        break;

      default:
        this.placeDefaultRows($this);
        break;
    }
  }

  placeTableRows($el) {
    $el.find('tbody').html(this.currentItems);
  }

  placeDefaultRows($el) {
    $el.find('.pagination-cnt__body').html(this.currentItems);
  }

  setCurrentItems(event) {
    const startNr = this.activePage === 0 ? 0 : ((this.activePage * this.perPage) - this.perPage);
    const endNr = this.activePage === 0 ? this.perPage : this.activePage * this.perPage;

    this.currentItems = this.totalItems.slice(startNr, endNr);
    this.placeRows(event);
    this.setPaginationNavigation(event);
  }

  prevClick(event) {
    event.preventDefault();
    this.activePage = this.activePage === 1 ? 1 : this.activePage - 1;
    this.setCurrentItems(event);
  }

  nextClick(event) {
    event.preventDefault();
    const lastPage = Math.ceil(this.totalItems.length / this.perPage);
    this.activePage = this.activePage === lastPage ? lastPage : this.activePage + 1;
    this.setCurrentItems(event);
  }

  handlePagination() {
    this.loadRows();
    this.setCurrentItems();

    //verbergen als de currentItems evenveel of minder zijn als de perPage
    if (this.totalItems.length <= this.perPage) {
      $(this.el).find('.pagination').addClass('hidden');
    }
  }

  setPaginationNavigation(event) {
    const $this = event === undefined
      ? $(this.container)
      : $(event.currentTarget).closest(this.container);

    $this.find('.pagination-current').text(this.activePage);
    $this.find('.pagination-total').text(Math.ceil(this.totalItems.length / this.perPage));
  }

  eventHandlers() {
    $('body')
      .find(this.el)
        .on('click', '.pagination__prev', this.prevClick.bind(this))
        .on('click', '.pagination__next', this.nextClick.bind(this));
  }
}
