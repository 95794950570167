import $ from 'jquery';
import debounce from 'lodash/debounce';

/* global window document */
function toggleMobileLt(event) {
  event.preventDefault();
  event.stopPropagation();

  const $cur = $(event.currentTarget);
  const $curPar = $cur.closest('.lt-block');

  if ($curPar.hasClass('mobile-closed')) {
    $curPar.removeClass('mobile-closed');
    $curPar.addClass('mobile-open');
  } else {
    $curPar.removeClass('mobile-open');
    $curPar.addClass('mobile-closed');
  }
}

function toggleSearch() {
  $('.search-form').toggleClass('is-active');
}

function eventNavClick(event) {
  event.preventDefault();
  if ($(window).width() < 768) {
    $(event.currentTarget).parent().toggleClass('is-active');
  }
}

function toggleSubmenu(event) {
  event.preventDefault();
  $(event.currentTarget).toggleClass('is-active');
}

function toggleExpandList(event) {
  event.preventDefault();
  const $par = $(event.currentTarget).closest('li');
  $par.toggleClass('is-active');
}

function toggleCart() {
  $('.shopping-cart').toggleClass('is-active');
}

function toggleNav(event) {
  event.preventDefault();
  $('.sub-nav').toggleClass('is-active');
  $('.sub-nav__content').removeClass('is-active');
  $('.search-form').removeClass('is-active');
  $('body').toggleClass('no-scroll-mobile');

}

function toggleDepartments(event) {
  event.preventDefault();
  $('#subnavDepartments').toggleClass('is-active');
}

function outsideClickHideNav(event) {
  //twijfelachtig hoor
    if (!$(event.target).is('.nav-click') &&
        !$(event.target).closest('.nav-click').length &&
        !$(event.target).is('.sub-nav') &&
        !$(event.target).closest('.sub-nav').length &&
        $('.sub-nav').is('.is-active'))
    {
        $('.sub-nav').removeClass('is-active');
    }
}

function mobileNavClose(event) {
  event.preventDefault();
  $('body').removeClass('no-scroll-mobile');
  $('.sub-nav').removeClass('is-active');
}

function noScrollIfNavIsActive() {
  if (
    $(window).width() < 768 &&
    ($('.sub-nav').hasClass('is-active') || $('.user-subnav').hasClass('is-active'))
  ) {
    $('body').addClass('no-scroll-mobile');
  }
}

function toggleUserNav(event) {
  event.preventDefault();
  $('.user-subnav').toggleClass('is-active');
  $('body').toggleClass('no-scroll-mobile');
}

function toggleInvoice(event) {
  const isChecked = event.target.checked;
  const invoiceForm = $('#invoiceExtra');

  if (isChecked) {
    invoiceForm.show();
  } else {
    invoiceForm.hide();
  }
}

function invoiceCheck() {
  const changeVoice = document.getElementById('changeInvoiceAddress');
  if (changeVoice !== null && changeVoice.checked) {
    $('#invoiceExtra').show();
  }
}

export function initStateEvents() {
  $('body')
    .on('click', '.lt-block .lt-block__header', toggleMobileLt)
    .on('click', '.event-nav-link', eventNavClick)
    .on('click', '.search-btn', toggleSearch)
    .on('click', '.submenu-btn', toggleSubmenu)
    .on('click', '.expand-list__link', toggleExpandList)
    .on('click', '.page-header__shop', toggleCart)
    .on('click', '.nav-click', toggleNav)
    .on('click', '#toggleDepartments', toggleDepartments)
    .on('click', '.mobile-nav-close', mobileNavClose)
    .on('click', '#user-btn', toggleUserNav)
    .on('change', '#changeInvoiceAddress', toggleInvoice);

  //check als de invoice aangevinkt staat
  invoiceCheck();

  //nav verbergen bij buiten klikken
  $(document).on('click', outsideClickHideNav);

  //zet de scroll uit als er geresized wordt
  $(window).on('resize', debounce(noScrollIfNavIsActive, 50));
}
