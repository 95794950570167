import $ from 'jquery';
import flatpickr from 'flatpickr';
import dutchLang from '../vendor/flatpickr.l10n.nl';

flatpickr.localize(dutchLang);

/*global document*/
function mockSelectChange(event) {
	const selectedText = $(event.target).find('option:selected').text();
	$(event.currentTarget).next().find('span').text(selectedText);
}

function addRadioActive(event, parentClassName) {
	const curRadio = $(event.target);
	const radioName = curRadio.attr('name');

	const parent = (parentClassName !== undefined)
		? parentClassName
		: curRadio.parents('.visual-radio').length ? '.visual-radio' : '.custom-radio';

	$(`input[name="${radioName}"]`)
		.closest(parent)
		.removeClass('is-active');

	curRadio
		.closest(parent)
		.addClass('is-active');
}

function toggleCustomCheckbox(event) {
	const curCheck = $(event.target);
	curCheck.parents('.custom-checkbox').toggleClass('is-active');
}

function toggleCheckboxBtn(event) {
	$(event.target).parents('.checkbox-btn').toggleClass('is-active');
}

function togglePayment(event) {
	$('.pay-toggle').removeClass('is-active');

	const targetId = $(event.target).parents('.pay-click').data('paymentid');
	$(`#${targetId}`).addClass('is-active');
}

function initFlatpickr() {
	const birth = document.querySelectorAll('.flatpickr');
	if (birth) {
		birth.flatpickr({
			dateFormat: 'm/d/Y',
			altInput: true,
			altFormat: "d/m/Y"
		});
	}
}

function setDefaultSelectVals() {
	$('.mock-select select').each((index, select) => {
		const selectedText = $(select).find('option:selected').text();
		$(select).next().find('span').text(selectedText);
	});
}

function setSelectedStates() {
	$('input[type="radio"], input[type="checkbox"]').each((index, input) => {
		if ($(input).prop('checked')) {
			$(input).closest('label').addClass('is-active');
		}
	});
}

function addressOptionToggle(event) {
	const $input = $(event.currentTarget);
	const inputName = $input.find('input').attr('name');

	$(`input[type="radio"][name="${inputName}"]`).each(function(){
		$(this).closest('.address-option').removeClass('is-active');
	});

	$input.addClass('is-active');
}

function togglePass(event){
	event.preventDefault();

	//toggle class voor css
	$(event.currentTarget).parent().toggleClass('is-showed');

	/*
		1. html ophalen (om alle attributen intact te laten!) en node opslaan
		2. controleren of het een pass/text is
		3. huidige input verwijderen en opgeslagen html node ombouwen op basis van de check
		4. nieuwe input op de juiste plek plaatsen
	*/

	let input = $(event.currentTarget).closest('.input-cnt').find('input');
	let value = input.val();
	let oldHtml = $('<div/>').append(input.prop('outerHTML'));
	let curText = '';
	let newType;

	//check wat voor type
	input.attr('type') === 'password' ? newType = 'text' : newType = 'password';

	//oude html ombouwen
	oldHtml.find('input').attr('type', newType).attr('value', value);

	//de huidige input weghalen
	input.remove();

	//tekst button veranderen
	curText = input.attr('type') === 'password' ? 'Verbergen' : 'Tonen';

	//nieuwe plaatsen
	const newHtml = oldHtml.html();
	$(newHtml).insertBefore(event.currentTarget);
	console.log('curText', curText);
	$(event.currentTarget).text(curText);
}

export function initCustomFormStates() {
	$('body')
		.on('change', '.mock-select select', mockSelectChange)
		.on('change', '.custom-radio input[type="radio"]', addRadioActive)
		.on('change', '.ws-product input[type="radio"]', (event) => addRadioActive(event, '.ws-product__mat-opt'))
		.on('change', '.custom-checkbox', toggleCustomCheckbox)
		.on('change', '.checkbox-btn input', toggleCheckboxBtn)
		.on('change', '.pay-click input', togglePayment)
		.on('click', '.address-option', addressOptionToggle)
		.on('click', '.show-pass__btn', togglePass)

	setDefaultSelectVals();
	setSelectedStates();
	initFlatpickr();
}
