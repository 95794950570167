import $ from 'jquery';
import debounce from 'lodash/debounce';

/*global window*/
function toggleImageMid(event) {
  if ($(window).width() < 1024) {
    return false;
  }

  //data ophalen
  const $cur = event.currentTarget === undefined ? $(event) : $(event.currentTarget);
  const newGradient = event.type === 'mouseenter' ? $cur.data('hover') : $cur.data('original');

  const curBg = $cur.css('backgroundImage');
  const curData = curBg.split(',');
  const newBg = curData[0];

  //achtergrond zetten
  $cur.css({
    backgroundImage: `${newBg} , linear-gradient(${newGradient})`
  });
}

function setHoverStateImageMid() {
  $('.image-mid--hover').each((index, el) => {
    const hoverVal = $(el).data('hover');
    const originalVal = $(el).data('original');
    const curBg = $(el).css('backgroundImage');
    const curData = curBg.split(',');
    const newBg = curData[0];

    if ($(window).width() < 1024) {
      $(el).css({
        backgroundImage: `${newBg}, linear-gradient(${hoverVal})`
      });
    } else {
      $(el).css({
        backgroundImage: `${newBg}, linear-gradient(${originalVal})`
      });
    }
  });
}

export function initBlockEvents() {
  $('body')
    .on('mouseenter', '.image-mid--hover', toggleImageMid)
    .on('mouseleave', '.image-mid--hover', toggleImageMid);

  setHoverStateImageMid();
  $(window).on('resize', debounce(setHoverStateImageMid, 100));
}
