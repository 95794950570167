import axios from 'axios';
import Popup from './Popup';

const baseUrl = `https://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;

/* global window */
export default class ImagePopup extends Popup {
  constructor(props) {
    super();

    this.count = 0; //number - aantal images
    this.imageDataCollection = []; //array - collection voor recursive
    this.imageContent = ''; //string - alle content
    this.btnCnt = props.btnCnt; //boolean - btnCnt?
    this.title = props.title; //string - titel
    this.sizeClass = props.sizeClass; //string - grootte vd popup

    const galleryJson = `${baseUrl}/departement/${props.deptId}/galerij`;
    this.createCollection(galleryJson);
  }

  getSingleImageHtml({ date, image, thumb, title_tag }) {
    const singleImage =
      `<div class="grid-cell">
        <article class="person-block person-block--col person-block--popup-img">
  				<figure class="person-block__img">
  					<img src="${baseUrl}/${thumb}" alt="">
  				</figure>
  				<section class="person-block__data">
  					<h4 class="person-block__title">${title_tag}</h4>
  					<span class="person-block__subtitle bold">${date}</span>
            <a
              href="${baseUrl}/${image}"
              class="person-block--popup__link bold"
              target="_blank">Afbeelding openen</a>
  				</section>
  			</article>
      </div>`;

    return singleImage;
  }

  getImageCntHtml(imagesHtml) {
    return `<div class="grid-wrapper grid-wrapper--three grid-wrapper--three-responsive-regular">
      ${imagesHtml}
    </div>`;
  }

  //1. eerst recursive door pagination loopen om collectie (array) te maken
  //2. collectie slaan we global op
  //3. door de collectie heenloopen om html te genereren
  //4. fillPopup() uitvoeren met gegenereerde html
  createCollection(url) {
    axios.get(url)
      .then(imageData => {
        const { data, next_page_url, total } = imageData.data;
        this.imageDataCollection = [...this.imageDataCollection, ...data];

        if (!next_page_url === null) {
          this.getCollection(next_page_url);
          return false;
        }

        this.count = total;
        this.createImagePopupContent();
      });
  }

  createImagePopupContent() {
    const totalImagesHtml = this.imageDataCollection
      .map(image => this.getSingleImageHtml(image))
      .join('');

    this.imageContent = this.getImageCntHtml(totalImagesHtml);

    this.fillPopup({
      btnCnt: this.btnCnt,
      title: this.title,
      count: this.count,
      content: this.imageContent,
      sizeClass: this.sizeClass
    });
  }
}
