import $ from 'jquery';

/*global window*/
function doConfirmLink(event) {
  const linkTarget = $(event.currentTarget).attr('href');
  window.location.href = linkTarget;
}

function setupLinkHandler(event, confirmFunc) {
  $('#popupConfirm')
    .off()
    .on('click', (e) => {
      e.preventDefault();

      //meegegeven functie moet worden uitgevoerd op click
      confirmFunc(event);

      //sluiten confirmBox
      cancelConfirm();
    });
}

function setConfirmboxContent({ boxtitle, texttitle, textcontent }) {
  const $confirmBox = $('.overlay .popup');

  //title opzetten
  const bt = boxtitle === undefined ? 'Actie' : boxtitle;
  $confirmBox.find('.lt-block__title').text(bt);

  //tekst opzetten
  if (texttitle) {
    $confirmBox.find('.popup__small-title').text(texttitle);
  }

  if (textcontent) {
    $confirmBox.find('p').text(textcontent);
  }
}

function handleConfirmLink(event) {
  event.preventDefault();
  const $cur = $(event.currentTarget);
  const $popup = $('.overlay .popup').closest('.overlay');
  const confirmType = $cur.data('type');
  let confirmFunc;

  //inhoud zetten
  setConfirmboxContent($cur.data());

  //confirmBox actief zetten
  $popup.addClass('is-active');

  //welke functie moet er uitgevoerd worden als je op bevestig klikt?
  switch (confirmType) {
    case 'link':
      confirmFunc = doConfirmLink;
      break;

    default:
      confirmFunc = () => { console.error('Er is geen type ingevoerd'); };
      break;
  }

  //clickhandler opzetten
  setupLinkHandler(event, confirmFunc);
}

function cancelConfirm() {
  $('.overlay .popup').closest('.overlay').removeClass('is-active');
}

function eventHandlers() {
  $('body')
    .on('click', '.confirm-click', handleConfirmLink)
    .on('click', '#popupCancel', cancelConfirm);
}

export function initConfirm() {
  eventHandlers();
}
