import $ from 'jquery';

export default class Popup {
  fillPopup({ btnCnt, title, count, content, sizeClass }) {
    const popup = $('.popup');
    popup.find('.lt-block__title').text(title);

    //count
    if (count) {
      popup
        .find('.lt-block__title')
        .append(`<span class="blue">${count}</span>`);
    }

    popup.find('.popup-btns').remove();

    //btnCnt
    if (btnCnt.add) {
      //foreach buttons met teksten
    }

    //content
    popup
      .find('.lt-block__content')
      .empty()
      .append(content);

    //size
    popup.addClass(sizeClass);

    //show
    $('.overlay').addClass('is-active');
  }

  closePopup() {
    $('.overlay').removeClass('is-active');
  }
}
