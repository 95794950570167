import { svgxuse } from 'svgxuse';
import $ from 'jquery';

//modules
import { initCustomFormStates } from './modules/CustomFormStates';
import { initStateEvents } from './modules/StateEvents';
import { initWebshop } from './modules/Webshop';
import { initBlockEvents } from './modules/BlockEvents';
import { initConfirm } from './modules/Confirm';

//classes
import SetupPopups from './classes/popup/SetupPopups';
import CreatePagination from './classes/pagination/CreatePagination';
import Upload from './modules/Upload';

//instances
const setupPopups = new SetupPopups();
const createPagination = new CreatePagination({
  perPage: 2,
  activePage: 1
});

const upload = new Upload();

/* global document */
$(document).ready(() => {
  // svgxuse();

  //classes init
  setupPopups.init();
  upload.initUpload();

  //modules init
  initCustomFormStates();
  initStateEvents();
  initWebshop();
  initBlockEvents();
  initConfirm();
});
