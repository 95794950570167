import $ from 'jquery';

function getProductMaterials() {
  return [
    {
      color: 'gold',
      msg: 'Goud'
    },
    {
      color: 'silver',
      msg: 'Zilver'
    },
    {
      color: 'bronze',
      msg: 'Brons'
    },
    {
      color: 'goldExtra',
      msg: 'Goud met briljant'
    }
  ];
}

function changeWebshopMaterial(event) {
  const el = event.target === undefined ? $(event) : $(event.target);
  matoptToggle(el);

  const matColor = el.data('color');
  const matMsg = el.data('msg');

  const $c = el.closest('.ws-mat-opt-cnt').find('.ws-current-color');
  const matArr = getProductMaterials();

  matArr.forEach((matObj) => {
    $c.removeClass(matObj.color);
  });

  $c.addClass(matColor);
  $c.text(matMsg);

  //button enabelen
  enableProductButton(el);
}

function matoptToggle(el) {
  el.closest('.ws-mat-opt-cnt').find('.ws-mat-opt').removeClass('is-active');
  el.closest('.ws-mat-opt').addClass('is-active');
}

function enableProductButton(el) {
  el
    .closest('.ws-product')
    .addClass('is-active')
    .find('button[type="submit"]')
    .removeAttr('disabled')
    .find('.svg-link__text')
    .text('Stel uw product samen');
}

export function checkIfMaterialIsChosen() {
  $('.ws-mat-opt-cnt').each((i, ws) => {
    $(ws).find('.ws-mat-opt input[type="radio"]').each((index, el) => {
      if ($(el).prop('checked')) {
        changeWebshopMaterial(el);
      }
    });
  });
}

function inscriptionCount(event) {
  const maxChars = $('#inscriptionMax').text();
  const inVal = $(event.target).val();

  $('#inscriptionWrittenChars').text(maxChars - inVal.length);
}

function toggleInscription() {
  $('#productInscription').toggle();
}

function webshopEvents() {
  $('body')
    .on('change', '.ws-mat-opt input[type="radio"]', changeWebshopMaterial)
    .on('keyup', '#productInscription', inscriptionCount)
    .on('change', '#noInscription', toggleInscription);
}

export function initWebshop() {
  checkIfMaterialIsChosen();
  webshopEvents();
}
