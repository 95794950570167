import $ from 'jquery';
import axios from 'axios';
import Popup from './Popup';

export default class ClickPopup extends Popup {
  constructor(props) {
    super();

    this.count = false; //number - aantal images
    this.btnCnt = props.btnCnt; //boolean - btnCnt?
    this.title = props.title; //string - titel
    this.sizeClass = props.sizeClass; //string - grootte vd popup
    this.clickContent = props.content;
    this.createClickPopupContent();
    this.setupClickFormHandler();
  }

  validateClickForm(event) {
    event.preventDefault();

    const form = $(event.currentTarget).closest('form');
    const url = form.attr('action');

    //next level es6
    const fields = [...event.currentTarget.querySelectorAll('*[name]')];

    const validateFields = fields.reduce((prev, next) => {
      const prevObj = prev;
      const inputName = $(next).attr('name');
      const inputVal = $(next).val();

      prevObj[inputName] = inputVal;
      return prevObj;
    }, {});

    this.postClickForm(url, validateFields, form);
  }

  postClickForm(url, validateFields, form) {
    axios.post(url, validateFields)
      .then(data => {
        if (!data.data.success) {
          this.appendValidation(data.data, form);
          return false;
        }

        this.appendClickDataToForm(data.data, form);
        this.closePopup();
      });
  }

  appendValidation(data, form) {
    Object.keys(data).forEach(key => {
      const input = form.find(`input[name=${key}]`);

      if (input.prev().hasClass('error')) {
        input.prev().text(data[key]);
        return false;
      }

      input.before(`<span class="error popup-form__error">${data[key]}</span>`);
    });
  }

  appendClickDataToForm(data, form) {
    Object.keys(data).forEach(key => {
      $(`[data-name=${key}]`).text(data[key]);
      form.find(`input[name="${key}"]`).attr('value', data[key]);
    });
  }

  setupClickFormHandler() {
    $('body').on('submit', '.popup form', this.validateClickForm.bind(this));
  }

  createClickPopupContent() {
    this.fillPopup({
      btnCnt: this.btnCnt,
      title: this.title,
      count: this.count,
      content: this.clickContent,
      sizeClass: this.sizeClass
    });
  }
}
