import $ from 'jquery';
import axios from 'axios';
import Cropper from 'cropperjs';

const baseUrl = `https://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
const uploadUrl = `${baseUrl}/profiel/photo/upload`;

/* global document window FileReader Cropper FormData  */
export default class Upload {
  constructor() {
    this.imageData = '';
    this.cropper = null;
    this.ulType = 'profile';
  }

  getImageData(event) {
    const reader = new FileReader();
    reader.readAsDataURL(event.currentTarget.files[0]);

    return new Promise(resolve => {
      reader.onload = (e) => {
        const imageData = e.target.result;
        if (imageData) {
          resolve({
            image: imageData
          });
        }
      };
    });
  }

  createCropper(imageBase) {
    return new Cropper(imageBase, {
      dragMode: 'move',
      aspectRatio: 1 / 1,
      setDragMode: 'move',
      autoCropArea: 0.9,
      scalable: true,
      viewMode: 0,
      restore: false,
      guides: false,
      center: false,
      highlight: false,
      cropBoxMovable: false,
      cropBoxResizable: false,
      toggleDragModeOnDblclick: false,
      cropend: () => {
        this.imageData = this.cropper.getCroppedCanvas({
          width: 300,
          height: 300
        });
      }
    });
  }

  cropImage(event) {
    const imageData = this.getImageData(event);
    const ulType = $(event.currentTarget).data('type');
    const oldCropper = this.cropper;

    //als er een huidige cropper is, eerst vernietigen, voordat we een nieuwe initten
    if (oldCropper) {
      oldCropper.destroy();
    }

    //cropproces in gang zetten
    imageData.then(data => {
      const { image } = data;
      $('#cropImage').attr('src', image);
      const imageBase = document.getElementById('cropImage');
      const cropper = this.createCropper(imageBase);

      //cropper in de state zetten
      this.cropper = cropper;
      this.ulType = ulType;

      //ui veranderingen
      $('.crop-cnt').addClass('is-active');
      $('.image-clicker__ul').addClass('is-active');
    });
  }

  uploadCanvasBlob(event) {
    event.preventDefault();

    const imageData = this.cropper.getCroppedCanvas({
      width: 300,
      height: 300
    });

    //appenden aan formdata
    if (imageData.toBlob) {
      imageData.toBlob(blob => {
        //upload url + parameters
        const uploadParams = { headers: { 'Content-Type': 'multipart/form-data' } };

        //formdata voor de BLOB
        const imageFormData = new FormData();
        imageFormData.append('file', blob);
        imageFormData.append('type', this.ulType);

        //uploaden met ajax
        axios.post(uploadUrl, imageFormData, uploadParams)
          .then(response => {
            this.cropper.destroy();

            $('.event-image').css({ backgroundImage: `url(${response.data})` });
            $('.image-clicker__ul').removeClass('is-active');
            $('.crop-cnt img').attr('src', '');
            
            //this.createCropper(response.data);
          });

          //filetype aangeven
      }, 'image/jpg');
    } else {
      console.error('toBlob wordt niet ondersteund!');
    }
  }

  eventHandlers() {
    $('body')
      .on('change', '#cropImageAndUpload', this.cropImage.bind(this))
      .on('click', '.image-clicker__ul', this.uploadCanvasBlob.bind(this));
  }

  initUpload() {
    this.eventHandlers();
  }
}
