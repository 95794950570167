import $ from 'jquery';
import ImagePopup from './ImagePopup';
import ClickPopup from './ClickPopup';

export default class SetupPopup {
  closePopup() {
    $('.overlay').removeClass('is-active');
  }

  newImagePopup(event) {
    const imagePopup = new ImagePopup({
      btnCnt: {
        add: false
      },
      title: 'Galerij ',
      count: true,
      content: true,
      sizeClass: 'large',
      deptId: $(event.currentTarget).data('deptid')
    });
  }

  generateClickArgs(event) {
    const $cur = $(event.currentTarget);
    const { title, count, sizeclass } = $cur.data();
    const content = $cur.find('.popup-btn__click-body').html();

    return {
      btnCnt: {
        add: false
      },
      title,
      count,
      content,
      sizeClass: sizeclass
    };
  }

  newClickPopup(event) {
    const clickArgs = this.generateClickArgs(event);
    const clickPopup = new ClickPopup(clickArgs);
  }

  setupPopups(event) {
    event.preventDefault();
    const popupType = $(event.currentTarget).data('type');

    switch (popupType) {
      case 'image':
        this.newImagePopup(event);
        break;

      case 'click':
        this.newClickPopup(event);
        break;

      default:
        return '';
    }
  }

  eventHandlers() {
    $('body')
      .on('click', '.popup-btn', this.setupPopups.bind(this))
      .on('click', '.close-popup', this.closePopup.bind(this));
  }

  init() {
    this.eventHandlers();
  }
}
